<template>
  <div class="card-header card-header-flex">
    <div class="d-flex flex-column justify-content-center mr-auto">
      <h5 class="mb-0">Semua Akun</h5>
    </div>
    <div class="row">
      <div class="d-flex flex-column justify-content-center mr-2">
        <a-select
          v-model="filterOb"
          label-in-value
          :default-value="{ key: 'all' }"
          style="width: 220px"
          @change="handleChangeObFilter">
          <a-select-option value="all">
            Semua Status On-Boarding
          </a-select-option>
          <a-select-option value="obcompleted">
            Sudah Selesai
          </a-select-option>
          <a-select-option value="obincompleted">
            Belum Selesai
          </a-select-option>
        </a-select>
      </div>
      <div class="d-flex flex-column justify-content-center mr-2">
        <a-select
          v-model="filterStatus"
          label-in-value
          :default-value="{ key: 'all' }"
          style="width: 180px"
          @change="handleChangeFilter">
          <a-select-option value="all">
            Semua Status
          </a-select-option>
          <a-select-option value="new">
            Baru
          </a-select-option>
          <a-select-option value="active">
            Aktif
          </a-select-option>
          <a-select-option value="inactive">
            Tidak aktif
          </a-select-option>
        </a-select>
      </div>
      <div class="d-flex flex-column justify-content-center">
        <a-input-search 
          v-model="searchText"
          placeholder="Temukan akun" 
          enter-button 
          @search="onSearch"/>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data: () => ({
    searchText: '',
    filterStatus: 'all',
    filterOb: { key: 'all' }
  }),
  created() {
    this.restoreState();
  },
  methods: {
    restoreState() {
      const searchText = this.$store.state.user.filter.searchText;
      this.filterStatus = this.$store.state.user.filter.filterStatus;
      this.filterOb = this.$store.state.user.filter.filterOnBoarding;
      this.searchText = searchText;
      console.log({ filter: this.filterStatus, filter2: this.filterOb });
    },
    handleChangeFilter(filterValue) {
      this.$emit('onChange', filterValue);
    },
    handleChangeObFilter(filterValue) {
      this.$emit('onChangeOb', filterValue);
    },
    onSearch(value) {
      this.$emit('onSearch', value);
    }
  }
}
</script>
