<template>
  <a-modal
    title="Undang Pengguna"
    :visible="visible"
    :confirm-loading="loading"
    @ok="handleOkInvite"
    @cancel="handleCancelInvite">
    <a-form :form="form">
      <a-form-item label="E-mail">
        <a-input placeholder="Ex: jhondoe@gmail.com"
          v-decorator="rules.email"/>
      </a-form-item>
      <a-form-item label='Role'>
        <a-select v-decorator="rules.role"
          placeholder="Pilih Role"
          style="width: 100%">
          <a-select-option value=0>Admin</a-select-option>
          <a-select-option value=1>Staff</a-select-option>
          <a-select-option value=2>Admin Pengguna</a-select-option>
          <a-select-option value=3>Admin Konten</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="Group">
        <a-select
          placeholder="Pilih Group"
          mode="multiple"
          style="width: 100%"
          :default-active-first-option="false"
          :show-arrow="true"
          :filter-option="false"
          :not-found-content="null"
          @search="handleSearchGroupTag"
          v-decorator="['groups']">
          <a-select-option
            v-for="group in groups"
            :key="group.name">
            {{ group.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import AccountModel from "./AccountModel";
import { createUser } from "@/api/account.api"
import { getListGroup } from "@/api/group.api";

export default {
  props: {
    visible: Boolean,
    loading: Boolean
  },
  data: () => {
    return {
      groups: [],
      rules: AccountModel.rules.invitation,
      form: null
    };
  },
  mounted() {
    this.form = this.$form.createForm(this)
  },
  methods: {
    handleCancelInvite() {
      this.$emit('cancel');
    },
    handleOkInvite() {
      this.form.validateFields().then(res => {
        this.$nprogress.start();
        let formData = new FormData();
        AccountModel.methods.buildFormNewData(formData, res);
        createUser(formData).then(() => {
          this.$emit('saved');
        }).catch(err => {
          console.error(err);
        }).finally(() => {
          this.$nprogress.done();
          this.$emit('cancel');
        })
      })
    },
    async handleSearchGroupTag(value) {
      if (value.length >= 1) {
        try {
          let params = {
            limit: 200,
            search: value,
            sort: 'desc'
          };
          let result = await getListGroup(params);
          this.groups = result.data.results;
        } catch (error) {/**/}
      }
    }
  }
}

</script>
