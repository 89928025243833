export default {
  table: {
    /**
     * Main view table column
     */
    columns: [{
      title: "Nama",
      dataIndex: "fullname",
      scopedSlots: {customRender: 'full_name'}
    },{
      title: "Role",
      dataIndex: "role_label",
      scopedSlots: {customRender: '_role'}
    },{
      title: "E-mail",
      dataIndex: "email",
      scopedSlots: {customRender: '_email'}
    },{
      title: "On-Boarding Progress",
      dataIndex: "contents",
      scopedSlots: {customRender: '_ob_progress'}
    },{
      title: "On-Boarding Status",
      dataIndex: "ob_status",
      scopedSlots: {customRender: '_ob_status'}
    },{
      title: "Aksi",
      dataIndex: "id",
      scopedSlots: {customRender: "action"}
    }],
    /**
     * Default pagination property
     */
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0
    }
  },

  filter: {
    filterKey: '',
    searchText: ''
  },

  rules: {
    /**
     * Define invitation rules
     */
    invitation: {
      email: ['email', {
        rules: [{
          type: 'email',
          message: 'Email tidak sesuai!'
        },{
          required: true,
          message: 'Email tidak boleh kosong!'
        }]
      }],
      role: ['role', {
        rules: [{
          required: true,
          message: 'Field ini tidak boleh kosong'
        }]
      }]
    }
  },
  methods: {
    /**
     * Get fetch params for list account
     * @param {Object} pagination 
     * @param {string} search 
     * @param {string} filter 
     * @returns 
     */
    getFetchParams(pagination, filter, obstatus, search) {
      let params = { search, filter, obstatus }
      console.log({ params });
      if(pagination) {
        const pageParams = {
          page: pagination.current,
          limit: pagination.pageSize || 10,
        }
        params = Object.assign(pageParams, params)
      }
      return params;
    },
    /**
     * Build form for new page
     * @param {FormData} formData 
     * @param {Object} data 
     */
    buildFormNewData(formData, data) {
      const firstname = data.email.split('@')[0] || '';
      formData.set("employee_number", firstname.substring(0, 20));
      formData.set("firstname", firstname);
      formData.set("lastname", "");
      formData.set("email", data.email);
      formData.set("gender", "");
      formData.set("pob", "");
      formData.set("dob", "");
      formData.set("mobile_no", "");
      formData.set("phone_no", "");
      formData.set("marital_status", "");
      formData.set("religion", "");
      formData.set("id_type", "");
      formData.set("id_number", "");
      formData.set("id_exp_date", "");
      formData.set("id_address", "");
      formData.set("id_zip_code", "");
      formData.set("address", "");
      formData.set("blood_type", "");
      formData.set("password", btoa(data.email));
      formData.set("role", data.role);
      formData.set("status", 0);
      formData.set("position", "");
      formData.set("placement", "");
      formData.set("groups", data.groups);
    }
  }
}
