<template>
  <div>
    <div class="air__utils__heading">
      <h5>
        <span class="mr-3">Daftar Akun</span>
        <div class="pull-right">
          <button class="btn btn-with-addon mr-auto btn-primary" v-on:click="handleClickInvite">
            <span class="btn-addon"><i class="btn-addon-icon fe fe-plus-circle"></i></span>
            Undang
          </button>
          <AccountInviteViewVue 
            :visible="visible"
            :loading="inviteLoading"
            @cancel="handleCancelInvite"
            @saved="handleSuccessInvite" />
        </div>
      </h5>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <AccountFilterVue
            @onChange="handleChangeFilter"
            @onChangeOb="handleChangeOb"
            @onSearch="onSearch" />
          <AccountListVue 
            :data="data"
            :obData="obData"
            :loading="loading"
            :pagination="pagination"
            @changePagination="handleTableChange" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { getListAccount } from "@/api/account.api"
import { getListContent } from "@/api/onboarding.api"
import { mapMutations } from "vuex"
import AccountModel from "./AccountModel";
import AccountFilterVue from "./AccountFilter.vue";
import AccountInviteViewVue from "./AccountInviteView.vue";
import AccountListVue from "./AccountList.vue";

export default {
  components: {
    AccountFilterVue,
    AccountInviteViewVue,
    AccountListVue
  },
  data() {
    return {
      // data
      data: [],
      obData: [],
      filterKey: '',
      filterOb: '',
      searchText: '',
      // table and loading prop
      pagination: AccountModel.table.pagination,
      loading: false,
      columns: AccountModel.table.columns,
      // invitation prop
      visible: false,
      inviteLoading: false,
      // Route
      curRoute: ''
    };
  },
  mounted() {
    this.curRoute = this.$route.fullPath;
    this.filterKey = this.$store.state.user.filter.filterStatus.key;
    this.filterOb = this.$store.state.user.filter.filterOnBoarding.key;
    this.searchText = this.$store.state.user.filter.searchText;
    this.fetchOnBoardingContent();
    this.fetchData();
  },
  beforeDestroy() {
    const nextRoute = this.$route.fullPath;
    if(!nextRoute.includes(this.curRoute)) {
      this.$store.commit('CLEAR_FILTER_USER');
      this.resetPagination();
    }
  },
  methods: {
    ...mapMutations([
      'UPDATE_DATA_ACCOUNT', 
      'UPDATE_FILTER_SEARCH', 
      'UPDATE_FILTER_STATUS',
      'UPDATE_FILTER_OB'
    ]),
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.fetchData();
    },
    async fetchData() {
      this.loading = true;
      const params = AccountModel.methods.getFetchParams(
        this.pagination, this.filterKey, this.filterOb, this.searchText
      )
      getListAccount(params).then(response => {
        if (response.status == 200) {
          this.loading = false;
          this.data = response.data.results;
          this.pagination.total = response.data.paging.total;
          this.UPDATE_DATA_ACCOUNT(this.data);
        }
      })
    },
    fetchOnBoardingContent() {
      getListContent().then(response => {
        if(response.status == 200) {
          this.obData = response.data;
        }
      })
    },
    async onSearch(value) {
      this.searchText = value;
      this.UPDATE_FILTER_SEARCH(this.searchText)
      this.resetPagination();
      this.fetchData()
    },
    handleChangeFilter(filterValue) {
      this.filterKey = filterValue.key;
      this.UPDATE_FILTER_STATUS(filterValue);
      this.resetPagination();
      this.fetchData()
    },
    handleChangeOb(filterValue) {
      this.filterOb = filterValue.key;
      this.UPDATE_FILTER_OB(filterValue);
      this.resetPagination();
      this.fetchData()
    },
    handleClickInvite() {
      this.visible = true;
    },
    handleCancelInvite() {
      this.visible = false;
    },
    handleSuccessInvite() {
      this.visible = false;
      this.fetchData();
    },
    resetPagination() {
      this.pagination.current = 1
    }
  }
};
</script>
