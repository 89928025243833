<template>
  <div class="card-body">
    <div class="air__utils__scrollTable">
      <a-table
        :columns="columns"
        :row-key="record => record.id"
        :data-source="data"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange">
        <span slot="full_name" slot-scope="full_name">
          <p v-if="full_name">{{ full_name }}</p>
          <p v-else> - </p>
        </span>
        <span slot="_role" slot-scope="_role">
          <p v-if="_role">{{ _role }}</p>
          <p v-else> - </p>
        </span>
        <span slot="_email" slot-scope="_email">
          <p v-if="_email">{{ _email }}</p>
          <p v-else> - </p>
        </span>
        <span slot="_ob_progress" slot-scope="_ob_progress">
          <p>{{ _ob_progress.length }} / {{ obData.length }}</p>
        </span>
        <span slot="_ob_status" slot-scope="_ob_status">
          <a-tag v-if="_ob_status == 1" color="green">Sudah Selesai</a-tag>
          <a-tag v-else color="red">Belum Selesai</a-tag>
        </span>
        <span slot="action" slot-scope="text">
          <router-link :to="{ path: 'account-management/view/' + text }">
            <button class="btn btn-sm btn-light mr-2">
              <i class="fe fe-eye mr-2"/>
              View
            </button>
          </router-link>
        </span>
      </a-table>
    </div>
  </div>
</template>
<script>
import AccountModel from "./AccountModel";

export default {
  props: {
    data: Array,
    obData: Array,
    loading: {
      type: Boolean,
      default: false
    },
    pagination: Object
  },
  data: () => ({
    columns: AccountModel.table.columns
  }),
  methods: {
    handleTableChange(pagination) {
      this.$emit('changePagination', pagination);
    }
  }
}
</script>
<style scoped>

</style>
