import httpClient from "@/api/httpClient";

const LIST_GROUP = "/api/users/groups";

const getListGroup = (_params) => httpClient.get(LIST_GROUP,{
    params: _params
})
const getDetailGroup = (_id) => httpClient.get(LIST_GROUP + "/" + _id, {})
const createGroup = payload =>
    httpClient.post(LIST_GROUP, payload, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
const updateGroup = (payload, id) =>
    httpClient.post(LIST_GROUP + "/" + id, payload, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Accept": "*",
        }
    });
const deleteGroup = (_id) => httpClient.delete(LIST_GROUP +"/"+ _id);

export {getListGroup, getDetailGroup, createGroup, updateGroup, deleteGroup}
