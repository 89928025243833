import httpClient from "@/api/httpClient";

const LIST_ACCOUNT = "/api/users";

const createUser = (_payload) => httpClient.post(LIST_ACCOUNT, _payload);
const getListAccount = (_params) => httpClient.get(LIST_ACCOUNT,{
    params: _params
})
const getDetailAccount = (_id) => httpClient.get(LIST_ACCOUNT + "/" + _id, {
})
const inactivatedAccount = (_id) => httpClient.post(LIST_ACCOUNT + "/" + _id + "/deactivating")
const activateAccount = (_payload, _id) => httpClient.post(LIST_ACCOUNT + "/" + _id + "/activating", _payload, {
    headers: {
        "Content-Type": "multipart/form-data",
        "Accept": "*/*"
    }
})
const editPersonalInformation = (_payload, _id) => httpClient.post(LIST_ACCOUNT + "/" + _id, _payload, {
    headers: {
        "Content-Type": "multipart/form-data",
        "Accept": "*/*"
    }
})
const bannedAccount = (_id) => httpClient.post(LIST_ACCOUNT + "/" + _id + "/banning")
const resendInvitation = (_payload) => httpClient.post(LIST_ACCOUNT + "/invitation/resend", _payload);
const resetPassword = (_id) => httpClient.post(LIST_ACCOUNT + "/" + _id + "/resetpassword");
const deleteAccount = (_id) => httpClient.post(LIST_ACCOUNT + "/" + _id + "/delete")
const permanentDeleteAccount = (_id) => httpClient.post(LIST_ACCOUNT + "/" + _id + "/harddelete")

export {createUser, deleteAccount, permanentDeleteAccount, getListAccount, inactivatedAccount, activateAccount, bannedAccount, getDetailAccount, editPersonalInformation, resendInvitation, resetPassword}
